import { useCallback, useRef } from 'react';
import styles from './CorporateSplitButton.module.css';
import { SplitButtonProps } from '@progress/kendo-react-buttons/dist/npm/ListButton/models/ListButtonProps';
import { SplitButton, SplitButtonItemClickEvent } from '@progress/kendo-react-buttons';
import { SplitButtonState } from '@progress/kendo-react-buttons/dist/npm/ListButton/SplitButton';

interface CorporateSplitButtonProps<T> extends SplitButtonProps {
  onClickItem(item: T): void;
  items: T[];
}

const CorporateSplitButton = <T extends unknown>({ onClickItem, ...other }: CorporateSplitButtonProps<T>) => {
  const splitButtonRef = useRef<SplitButton>(null);

  const openSplitButton = (): void => {
    const currentStatus = splitButtonRef.current?.state.opened;
    return splitButtonRef.current?.setState({ opened: !currentStatus } as SplitButtonState);
  };

  const onItemClick = useCallback((event: SplitButtonItemClickEvent): void => {
    const { item } = event;
    onClickItem(item);
  }, [onClickItem]);

  return (
    <SplitButton
      className={styles['split-button']}
      onButtonClick={openSplitButton}
      onItemClick={onItemClick}
      icon=" fas fa-id-badge"
      textField="name"
      ref={splitButtonRef}
      {...other}
    />
  );
};

export default CorporateSplitButton;
