import { Button, Toolbar, ToolbarItem } from '@progress/kendo-react-buttons';
import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { StorageKind } from '../../constants/document';
import { getAuthorizations } from '../../reducers/user/selectors';
import CorporateSplitButton from '../buttons/CorporateSplitButton';
import styles from './DocumentsActions.module.css';

interface ItemsSplitButton { icon: string; text: string }

const itemsInsertButton: ItemsSplitButton[] = [{ icon: 'folder-add', text: 'Folder' }, { icon: 'file-add', text: 'File' }];
const itemsDeleteButton: ItemsSplitButton[] = [{ icon: 'files', text: 'Only content' }, { icon: 'folder', text: 'Content and folder' }];

const DocumentsActions = ({
  item,
  onClickAddFolder,
  onClickAddDocument,
  onClickRenameDocument,
  onClickRenameFolder,
  onClickDeleteDocument,
  onClickDeleteFolder,
  onClickShareDocument,
  onClickDeleteContentFolder
}: any) => {
  const authorizations = useSelector(getAuthorizations);

  const newInsertRequest = useCallback(
    (item: ItemsSplitButton) =>
      item.text === 'Folder' ? onClickAddFolder(item) : onClickAddDocument(item),
    [],
  );

  const newDeleteRequest = useCallback(
    (item: ItemsSplitButton) =>
      item.text === 'File' ? onClickDeleteContentFolder(item) : onClickDeleteFolder(item),
    [],
  );

  return item === null ||
    !authorizations.filter((a) => (item.path === undefined ? false : item.path.startsWith(a.resource)))[0]?.edit ? (
    <Toolbar></Toolbar>
  ) : (
    <Toolbar>
      {item.kind === StorageKind.document ? (
        <>
          <ToolbarItem>
            <Button
              icon="edit-tools"
              className={styles['action-toolbar-button']}
              title="Rename file"
              onClick={() => onClickRenameDocument(item)}
            >
              Rename file
            </Button>
          </ToolbarItem>
          <ToolbarItem>
            <Button
              icon="delete"
              className={styles['action-toolbar-button']}
              title="Delete file"
              onClick={() => onClickDeleteDocument(item)}
            >
              Delete file
            </Button>
          </ToolbarItem>
          <ToolbarItem>
            <Button
              icon="share"
              className={styles['action-toolbar-button']}
              title="Share file"
              onClick={() => onClickShareDocument(item)}
            >
              Share file
            </Button>
          </ToolbarItem>
        </>
      ) : (
        <>
          <ToolbarItem>
            <CorporateSplitButton
              onClickItem={newInsertRequest}
              text="Insert"
              icon='plus'
              items={itemsInsertButton}
            />
          </ToolbarItem>
          <ToolbarItem>
            <Button
              icon="edit-tools"
              className={styles['action-toolbar-button']}
              title="Rename folder"
              onClick={() => onClickRenameFolder(item)}
            >
              Rename Folder
            </Button>
          </ToolbarItem>
          <ToolbarItem>
            <CorporateSplitButton
              onClickItem={newDeleteRequest}
              text="Delete"
              icon='delete'
              items={itemsDeleteButton}
            />
          </ToolbarItem>
          <ToolbarItem>
            <Button
              icon="share"
              className={styles['action-toolbar-button']}
              title="Share folder"
              onClick={() => onClickShareDocument(item)}
            >
              Share Folder
            </Button>
          </ToolbarItem>
        </>
      )}
    </Toolbar>
  );
};

export default DocumentsActions;
